<template>
    <!-- 导航 -->
    <page-head title="球员数据分析" />
    <content-bg>
        <template v-slot:content>
            <data-report-structure>
                <template v-slot:left v-if="pageType === 'player'">
                    <select-style :list="userTeamList" label="name" key="id" v-model:value="teamId"
                                  @changeFn="changeFn"></select-style>
                </template>
                <template v-slot:center>{{playerName}}</template>
                <template v-slot:right>{{$moment().format('YYYY')}} 01/01 - {{$moment().format('MM/DD')}}</template>
                <template v-slot:content>
                    <div class="barBox" v-for="(item, index) in dataSource" v-if="filterArr.length && GPSData.length">
                        <div class="title">
                            <p>{{ item.displayName }}</p>
                        </div>
                        <bar-chart
                            :xData="item.xData"
                            :yData="item.seriesData"
                            :name="item.displayName"
                            class="bar_chart"></bar-chart>
                    </div>
                    <no-data v-else></no-data>
                </template>
            </data-report-structure>
        </template>
    </content-bg>
</template>

<script>
import {useRoute} from "vue-router";
import {getCurrentInstance, reactive, toRefs} from "vue";
import {useStore} from "vuex";
import barChart from '../components/bar-chart'

export default {
    name: "details",
    components: {
        barChart
    },
    setup() {
        const route = useRoute()
        const {proxy} = getCurrentInstance()
        const store = useStore()

        const state = reactive({
            playerName: route.query.name,
            pageType: route.query.pageType,
            filterArr: [],

            GPSData: [],
            dataLength: 15, // 只取15条数据

            dataSource: [],
            userTeamList: [],
            teamId: {}
        });

        //  获取gps 筛选项
        const getFilter = () => {
            const {id} = store.state.teamData;
            let data = {
                teamId: id
            }
            proxy.$server.getGpsFilter(data).then(res => {
                if (res.code === 200) {
                    if (res.data && res.data.length) {
                        let arr = [];
                        res.data.map(item => {
                            if (item.gps) {
                                arr.push(item)
                            }

                        })
                        state.filterArr = arr;
                        getData()
                    }
                }
            })
        }

        const getData = () => {
            const {id} = store.state.teamData;
            let data = {
                playerId: route.query.id,
                // playerId: '6100f3c24b2c714800ef6f8d',
                teamId: id,
                startDate: proxy.$Config.currentYearFirstDay,
                endDate: proxy.$moment().format('YYYY/MM/DD')
            }
            proxy.$server.getGpsPlayerReport(data).then(res => {
                state.filterArr.forEach(item => {
                    item.seriesData = []
                    item.xData = []
                })
                state.GPSData = res.data || []
                if (!res.data || !res.data.length) {
                    return
                }
                state.GPSData.forEach((item, index) => {
                    if (index < state.dataLength) {
                        state.filterArr.forEach(gps => {
                            const currentGps = item.data.find(dataValue => dataValue.slug === gps.slug)
                            if (currentGps && currentGps.value) {
                                gps.seriesData.push({
                                    value: Number(currentGps.value).toFixed(gps.decimal),
                                    timeFormat: gps.timeFormat,
                                    percentage: gps.percentage,
                                    name: gps.displayName
                                })
                            } else {
                                gps.seriesData.push({
                                    value: '',
                                    timeFormat: gps.timeFormat,
                                    percentage: gps.percentage,
                                    name: gps.displayName
                                })
                            }
                            gps.xData.push(item.date)
                        })
                    }else {
                        return
                    }
                })

                state.dataSource = JSON.parse(JSON.stringify(state.filterArr))
            })
        }

        if (state.pageType === 'player') {
            const params = {
                userId: route.query.id,
                userType: 'player'
            }
            proxy.$server.getUserTeams(params)
                .then(res => {
                    state.userTeamList = res.data;
                    changeFn(state.userTeamList[0])
                })
        } else {
            getFilter();
        }

        const changeFn = (item) => {
            state.teamId = item;
            store.commit('setTeamData', item)
            getFilter();
        }

        return{
            ...toRefs(state),

            changeFn
        }
    }
}
</script>

<style scoped lang="scss">
.barBox {
    height: 680px;
    width: 100%;
    .title {
        position: relative;
        p{
            padding-top: 24px;
            padding-bottom: 18px;
            font-size: 22px;
            line-height: 31px;
            color: rgba(255, 255, 255, .8);
            font-family: PingFangMedium;
            padding-left: 50px;
        }
    }

    .bar_chart {
        height: calc(100% - 80px);
        width: 100%;
    }
}
</style>