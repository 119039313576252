<template>
    <div class="bar_chart" ref="barChart"></div>
</template>
<script>
import * as echarts from 'echarts';

export default {
    name: "bar-chart",
    props: {
        xData: {
            type: Array,
            default: () => []
        },
        yData: {
            type: Array,
            default: () => []
        },
        name: {
            type: String,
            default: () => ''
        },
        date: {
            type: String,
            default: () => ''
        },
        diffOption: {
            type: Object,
            default: () => ({})
        },
        barItemWidth: {
            type: Number,
            default: 75
        }
    },
    data() {
        return {};
    },
    mounted() {
        setTimeout(() => {
            this.init();
        })
    },
    methods: {
        init() {
            if ((this.yData.length * 100) > this.$refs.barChart.offsetWidth) {
                this.$refs.barChart.style.width = (this.yData.length * 100) + 'px'
            }

            let maxNumber = Math.max(...this.yData) + 10
            let maxArr = new Array(this.yData.length).fill(maxNumber)
            let myChart = echarts.init(this.$refs.barChart);




            myChart.resize();

            let option = {
                grid: {
                    top: '80',
                    left: '50',
                    right: '50',
                    bottom: '40',
                    containLabel: true,
                },
                xAxis: [
                    {
                        show: true,
                        type: "category",
                        data: this.xData,
                        axisLabel: {
                            //坐标轴刻度标签的相关设置。
                            show: true,
                            interval: 0, // 强制显示所有x轴name
                            rotate: 30,
                            textStyle: {
                                color: 'rgba(255, 255, 255, .6)',
                                fontSize: 24,
                                fontFamily: 'akrobatRegular',
                            },
                            margin: 15,
                        },
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: false
                        },
                    },
                ],

                yAxis: [
                    {
                        type: "value",
                        min: 0,
                        axisLabel: {
                            //坐标轴刻度标签的相关设置。
                            show: true,
                            textStyle: {
                                color: 'rgba(255, 255, 255, .6)',
                                fontSize: 26,
                                fontFamily: 'akrobatRegular',
                            },
                        },
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        splitLine: {
                            lineStyle: {
                                color: 'rgba(255, 255, 255, .3)',
                                type: 'dotted'
                            },
                            show: true
                        },
                        show: true,
                    },
                ],
                series: [
                    {
                        type: "bar",
                        barWidth: 16,
                        zlevel: 10,
                        data: this.yData,
                        itemStyle: {
                            normal: {
                                color: {
                                    type: "linear",
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: "rgba(90, 129, 237, 1)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(54, 99, 225, 1)",
                                        },
                                    ],
                                },
                                barBorderRadius: [10, 10, 10, 10],
                            },
                        },
                        label: {
                            show: true,
                            position: 'top',
                            verticalAlign: 'middle',
                            distance: 15,
                            fontSize: 26,
                            color: 'rgba(255, 255, 255, .8)',
                            fontFamily: 'akrobatRegular',
                            formatter: (params) => {
                                const value = Number(params.value)
                                if (params.data.timeFormat) {
                                    if (Number.isNaN(value)) {
                                        return ''
                                    } else {
                                        return this.timeFormat(value)
                                    }
                                } else if (params.data.percentage) {
                                    if (Number.isNaN(value)) {
                                        return ''
                                    } else {
                                        return value + '%'
                                    }
                                } else {
                                    return params.value
                                }
                            }
                        },
                    },
                ],
            };

            if (Object.keys(this.diffOption).length) {
                for (let i in this.diffOption) {
                    eval('option.' + i + '=' + this.diffOption[i])
                }
            }

            myChart.setOption(option, true);
            window.addEventListener('resize', function () {
                myChart.resize();
            });
        },
        // 秒数转换时分秒 180秒 => 00:03:00
        timeFormat(value) {
            var theTime = parseInt(value); // 秒
            var middle = 0; // 分
            var hour = 0; // 小时
            let addZero = (num) => {
                if (!num) return '00';
                return num < 10 ? '0' + num : num;
            };

            if (theTime > 60) {
                middle = parseInt(theTime / 60);
                theTime = parseInt(theTime % 60);
                if (middle > 60) {
                    hour = parseInt(middle / 60);
                    middle = parseInt(middle % 60);
                }
            }
            return addZero(parseInt(hour)) + ':' + addZero(parseInt(middle)) + ':' + addZero(parseInt(theTime));
        },
    }
}
</script>
<style lang="scss" scoped>
.bar_chart {
    width: 100%;
    height: 100%;
}
</style>